import { ref } from '@nuxtjs/composition-api';

const getMenuIsShown = ref(true);

export default function useMenuVisible() {
  const setMenuIsShown = (val) => {
    console.log('sanity visibile', val);
    getMenuIsShown.value = val;
  };

  return { setMenuIsShown, getMenuIsShown };
}
